import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import ContentPage from "@/components/FinalSapientia23"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import Header from "@/components/Header"

const FinalSapientia2022 = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Final Sapientia 2023">
          <Header />
          <ContentPage />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default FinalSapientia2022;
