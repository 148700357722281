import React, { Fragment } from "react"

const Finalistas = ({}) => {
  return (
    <Fragment>
      <section className="pageLanding PT-160 bg-video">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" style={{ marginTop: 60, marginBottom: 60 }}>
              <h3 style={{ marginBottom: 32 }} className='title-w'>Final Sapientia 2023</h3>
              <iframe src="https://vimeo.com/event/2575897/embed/interaction" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen allowfullscreen style={{ width: '100%', height: 600 }} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Finalistas;
